.imageViewerArrowRight {
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 2px;
  height: 4em;
  width: 4em;
  z-index: 1;
}

.imageViewerArrowLeft {
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 2px;
  height: 4em;
  width: 4em;
  z-index: 1;

  rotate: 180deg;
}

.react-simple-image-viewer__next {
  opacity: 1 !important;
}

@media screen and (max-width: 600px) {
  .imageViewerArrowRight {
    height: 2em;
    width: 2em;
  }

  .imageViewerArrowLeft {
    height: 2em;
    width: 2em;
  }
}
