.imageItem {
  height: 80%;
  width: 80%;
  object-fit: contain;
  object-position: center;
}

.facebookV1 {
  display: grid;
  grid-template-columns: 1fr 0.95fr;
  gap: 5px;
}

.rightContainerV1 {
  display: grid;
  gap: 5px;
}

.facebookV2 {
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  gap: 5px;
}

.rightContainerV2 {
  display: grid;
  grid-template-rows: 130px 130px 130px;
  gap: 5px;
}

.facebookV3 {
  display: grid;
  grid-template-rows: auto auto;
  gap: 5px;
}

.topContainerV3 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
}

.bottomContainerV3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.gridGallery {
  display: grid;
  grid-template-rows: 1fr;
  gap: 5px;
}

.gridGalleryRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.gridGalleryItem {
  width: 130px;
  height: 133px;
  width: 100%;
  height: 100%;
  max-width: 133px;
  max-height: 133px;
  object-fit: cover;
  display: block;
}

.splideImg {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
}

.mosaicImageItem {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.imageCounterWrapper {
  height: 100%;
  position: relative;
}

.imageCounter {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  height: 100%;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}
