.react-simple-image-viewer__next {
  opacity: 1 !important;
  height: 20% !important;
}

.react-simple-image-viewer__close {
  opacity: 1 !important;
  margin-top: 50px !important;
}

.react-simple-image-viewer__previous {
  opacity: 1 !important;
  height: 20% !important;
}

.react-simple-image-viewer__modal-content {
  width: 75% !important;
  height: 90% !important;
}
