/* Button styles */

.button_square {
  border-width: 2px;
  border-style: solid;
  border-radius: 0 !important;
}

.button_rounded {
  border-width: 2px;
  border-style: solid;
  border-radius: 100px !important;
}

.button_radius {
  border-width: 2px;
  border-style: solid;
  border-radius: 10px !important;
}

/* Icon styles */

.icon_small {
  width: 34px;
  height: 34px;
}

.icon_medium {
  width: 38px;
  height: 38px;
}

.icon_large {
  width: 48px;
  height: 48px;
}

/* Theme 1 styles */

.theme1_text {
  color: #1f2124;
}

.theme1_divider {
  background: #1f2124;
}

.theme1_btn {
  background-color: #6474e5;
  color: #ffffff;
  border: 2px solid #1f2124;
  box-shadow: 6px 6px 0 #1f2124;
}

/* Theme 2 styles */

.theme2_text {
  color: #1f2124;
}

.theme2_divider {
  background: #1f2124;
}

.theme2_btn {
  background-color: #194355;
  color: #ffffff;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

/* Theme 3 styles */

.theme3_text {
  color: #2a1c2f;
}

.theme3_divider {
  background: #1f2124;
}

.theme3_btn {
  background-color: #2a1c2f;
  color: #ffffff;
  border: none;
  box-shadow: none;
  border-radius: 12px;
}

/* Theme 4 styles */

.theme4_text {
  color: #ffffff;
}

.theme4_divider {
  background: #ffffff;
}

.theme4_btn {
  background: linear-gradient(90deg, #26cbff 0.29%, #cb6bed 99.42%);
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 100px;
}
