.container {
  text-align: center;
}

.ratingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 0 15px;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.reviewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.reviewTextArea {
  margin: 10px;
  font-size: 16px;
  padding: 15px;
  height: 100px;
  width: 100%;
  outline: none;
}

.emailContainer {
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.emailInput {
  font-size: 16px;
  padding: 15px;
  border: 1px solid #000;
  outline: none;
}

.emailInputError {
  border: 1px solid red;
}

.errorText {
  opacity: 0;
  color: red;
  transition: all 0.3s;
  text-align: start;
}

.showError {
  opacity: 1;
}

.iconBtn {
  font-size: 80px;
  cursor: pointer;
}

.iconText {
  font-size: 18px;
}

.thankYouImg {
  width: 100%;
  object-fit: cover;
  margin-bottom: 1rem;
}
