/*
    SF PRO DISPLAY
*/

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYBOLD.OTF");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYMEDIUM.OTF");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYREGULAR.OTF");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYBLACKITALIC.OTF");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYHEAVYITALIC.OTF");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYSEMIBOLDITALIC.OTF");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYLIGHTITALIC.OTF");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYTHINITALIC.OTF");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("../fonts/sf-pro-display/SFPRODISPLAYULTRALIGHTITALIC.OTF");
  font-weight: 100;
  font-style: italic;
}

/* 
    SF PRO TEXT
*/

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-BoldItalic.otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-Heavy.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-HeavyItalic.otf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-Semibold.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-SemiboldItalic.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-Medium.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-MediumItalic.otf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-Regular.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-RegularItalic.otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-Light.otf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Text";
  src: url("../fonts/sf-pro-text/SF-Pro-Text-LightItalic.otf");
  font-weight: 100;
  font-style: italic;
}
