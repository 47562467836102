.container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 50px 8px 150px 8px;
}

.carousel {
  display: flex;
  justify-content: center;
}

.profilePicture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto 18px;
  display: block;
  object-fit: cover;
}

.title {
  font-family: "SF-Pro-Display", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.35px;
  width: 100%;
  margin: 0 auto 6px;
  text-align: center;
}

.subTitle {
  font-family: "SF-Pro-Text", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  text-align: center;
  margin: 0 auto;
  max-width: 290px;
}

.subTitleProfile {
  font-family: "SF-Pro-Text", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  text-align: center;
  margin: 0 auto 12px;
  max-width: 290px;
}

.contactWrapper,
.socialWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.contactWrapper button,
.socialWrapper button {
  text-decoration: none;
  margin: 0 10px;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  border: 0;
  background: 0;
}

.contactWrapper button img,
.socialWrapper button img {
  width: 44px;
  height: 44px;
}

.primaryBtn {
  display: flex;
  width: 100%;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 12px;
  margin: 0 0 14px;

  font-family: "SF-Pro-Text";
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;

  background-color: #6474e5;
  border: 2px solid #1f2124;
  border-radius: 10px;
  box-shadow: 6px 6px 0 #1f2124;
  transform: translate(-3px, -3px) scale(0.97);
  transition: all 0.35s ease-out;
}

.primaryBtn:focus {
  box-shadow: 0 0 0 #1f2124;
  transform: translate(0, 0) scale(0.97);
}

input.primaryBtn {
  margin-bottom: 0;
  cursor: pointer;
}

.stayTuned {
  margin-top: 32px;
}

.videoWrapper {
  display: block;
  margin: 32px auto;
}

.videoWrapper iframe {
  border-radius: 10px;
}

.getInTouch {
  background-color: #fff;
  border-radius: 10px;
  padding: 32px 16px;
  margin-bottom: 32px;
}

.formTitle {
  font-family: "SF-Pro-Text", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  text-align: center;
  margin: 0 0 16px;
}

.getInTouch input:not(:last-child) {
  border: 1px solid #1f2124;
  width: 100%;
  padding: 15px 15px;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #686868;
  margin-bottom: 20px;
}

.saveContact {
  margin: 32px 17px;
}

.profileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 0 10px;
  border-radius: 50%;
  cursor: pointer;
  color: rgb(251, 251, 251);
  font-size: 16px;
}

.addressContainer {
  margin-top: 32px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  gap: 10px;
}

.addressContainer > p {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

/* Splide styles */

.splide__slide img {
  width: 100%;
  margin: 20px 0;
}

.splide__pagination {
  position: relative;
  padding: 0;
  bottom: 0;
}

.splide__pagination__page.is-active {
  background: #1f2124;
  transform: scale(1);
  opacity: 1;
  height: 7px;
  width: 7px;
}

.splide__pagination__page {
  background: #1f2124;
  opacity: 0.3;
  height: 7px;
  width: 7px;
}

.splide__arrow {
  background: rgba(0, 0, 0, 0.24);
  opacity: 1;
  width: 32px;
  height: 32px;
}

.splide__arrow svg {
  fill: #fff;
  width: 14px;
  height: 14px;
}

.splide__arrow--next {
  right: 10px;
}

.splide__arrow--prev {
  left: 10px;
}

.splide__arrow:disabled {
  display: none;
}

@media screen and (min-width: 425px) {
  .container {
    max-width: 400px;
    margin: 0 auto;
    padding: 50px 0px 150px 0px;
  }
}
