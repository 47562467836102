@import url("../assets/styles/fonts.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SF-Pro-Display";
}

a {
  text-decoration: none;
}

.App {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;
}

button {
  cursor: pointer;
}
